import { Picker } from "@react-native-picker/picker"
import { Button, Card, Input, Text } from "@rneui/themed"
import { useTranslation } from "react-i18next"
import { Platform } from "react-native"
import { MedicalProfileProps } from "../../../@types/components/medicalProfile"
import MobileDatePicker from "@react-native-community/datetimepicker"
import { Physician } from "../../../@types/redux/medicalProfile"
import { EMPLOYMENT_STATUS_OPTIONS, ETHNICITY_OPTIONS, GENDER_OPTIONS, RACE_OPTIONS } from "../../../constants/general"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { addPhysician, setDateOfBirth, setEmploymentStatus, setEthnicity, setFirstName, setGender, setHeardAbout, setLastName, setOccupationTitle, setPhoneNumber, setRace } from "../../redux/medicalProfileState"
import { AppState } from "../../redux/store"
import { PhysicianComponent } from "../Physician/Physician"
import { styles } from "./styles"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useState } from "react"
import { showToast } from "../../../toast"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import { isPossiblePhoneNumber } from "react-phone-number-input"


export const MedicalProfile = (props: MedicalProfileProps) => {
	const { handleSubmit, shouldShowSubmitButton } = props

	const { t } = useTranslation(["translation", "screens"])


	const dispatch = useAppDispatch()
	const medProfile = useAppSelector((state: AppState) => state.medicalProfileState.profile)
	const medErrors = useAppSelector((state: AppState) => state.medicalProfileState.errors)

	// Local state to track phone number validation error
	const [phoneError, setPhoneError] = useState<string | null>(null)

	const handlePhoneNumberChange = (value: string | undefined) => {
		dispatch(setPhoneNumber(value || ""))

		// Check if phone number is valid
		if (value && !isPossiblePhoneNumber(value)) {
			setPhoneError("Invalid phone number")
		} else {
			setPhoneError(null)  // Clear the error if phone number is valid
		}
	}

	const genderOptions = t("genderOptions") as unknown as string[]
	const raceOptions = t("raceOptions") as unknown as string[]
	const ethnicityOptions = t("ethnicityOptions") as unknown as string[]
	const employmentStatusOptions = t("employmentStatusOptions") as unknown as string[]

	const [selectedDate, setSelectedDate] = useState(new Date(medProfile.date_of_birth))

	const currentYear = new Date().getFullYear()
	const startYear = currentYear - 100  // 100 years ago
	const endYear = currentYear - 18     // 18 years ago to ensure adult users

	const years = Array.from({ length: endYear - startYear + 1 }, (v, k) => k + startYear)


	// const years = Array.from({ length: new Date().getFullYear() - 1990 + 1 }, (v, k) => k + 1990);
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	]

	const calculateAge = (dob: Date) => {
		const birthday = new Date(dob)
		const today = new Date()
		let age = today.getFullYear() - birthday.getFullYear()
		const m = today.getMonth() - birthday.getMonth()
		if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
			age--
		}
		return age
	}


	const handleDateChange = (date: Date) => {
		const age = calculateAge(date)
		if (age < 18) {
			showToast({message: "You must be at least 18 years old to register", kind: "failure"})
			return
		}
		setSelectedDate(date)
		dispatch(setDateOfBirth(date))
	}



	return (
		<Card containerStyle={styles.card}>
			<Card.Title style={styles.cardTitle} h3>{t("medicalInformation")}</Card.Title>
			<Card.Divider/>
			<Input
				style={styles.input}
				label={t("firstName")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={medProfile.first_name}
				onChangeText={(val) => dispatch(setFirstName(val))}
				errorMessage={medErrors.first_name?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("lastName")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={medProfile.last_name}
				onChangeText={(val) => dispatch(setLastName(val))}
				errorMessage={medErrors.last_name?.join("\n")}/>
			<Text style={styles.labelText}>{t("gender")}</Text>	
			<Picker
				style={styles.picker}
				prompt={t("gender") || undefined}
				selectedValue={medProfile.gender}
				onValueChange={(val) => dispatch(setGender(val))}>
				{genderOptions.map((option, i) => <Picker.Item key={i} label={option} value={GENDER_OPTIONS[i]}/>)}
			</Picker>
			<Text style={styles.labelText}>{t("dateOfBirth")}</Text>
			{
				Platform.OS == "web" ?
					<DatePicker
						renderCustomHeader={({
							date,
							changeYear,
							changeMonth,
							decreaseMonth,
							increaseMonth,
							prevMonthButtonDisabled,
							nextMonthButtonDisabled,
						}) => (
							<div
								style={{
									margin: 10,
									display: "flex",
									justifyContent: "center",
								}}
							>
								<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
									{"<"}
								</button>
								<select
									value={date.getFullYear()}
									onChange={({ target: { value } }) => changeYear(Number(value))}
								>
									{years.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</select>

								<select
									value={months[date.getMonth()]}
									onChange={({ target: { value } }) =>
										changeMonth(months.indexOf(value))
									}
								>
									{months.map((option) => (
										<option key={option} value={option}>
											{option}
										</option>
									))}
								</select>

								<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
									{">"}
								</button>
							</div>
						)}
						selected={selectedDate}
						onChange={handleDateChange}
					/>
					:
					<MobileDatePicker
						style={styles.dateInput}
						value={new Date(medProfile.date_of_birth) || new Date()}
						mode="date"
						onChange={(event) => {
							const date = new Date(event.nativeEvent.timestamp || 0)
							const age = calculateAge(date)
							if (age < 18) {
								showToast({message: "You must be at least 18 years old to register", kind: "failure"})
								return
							}
							dispatch(setDateOfBirth(date))
						}}
					/>
			}
			<Text style={styles.labelText}>{t("ethnicity")}</Text>
			<Picker
				style={styles.picker}
				selectedValue={medProfile.ethnicity}
				onValueChange={(val) => dispatch(setEthnicity(val))}>
				{ethnicityOptions.map((option, i) => <Picker.Item key={i} label={option} value={ETHNICITY_OPTIONS[i]}/>)}
			</Picker>

			<Text style={styles.labelText}>{t("race")}</Text>
			<Picker
				style={styles.picker}
				selectedValue={medProfile.race}
				onValueChange={(val) => dispatch(setRace(val))}>
				{raceOptions.map((option, i) => <Picker.Item key={i} label={option} value={RACE_OPTIONS[i]}/>)}
			</Picker>
				
			<Text style={styles.labelText}>{t("employmentStatus")}</Text>
			<Picker 
				style={styles.picker}
				selectedValue={medProfile.employment_status}
				onValueChange={(val) => dispatch(setEmploymentStatus(val))}>
				{employmentStatusOptions.map((option, i) => <Picker.Item key={i} label={option} value={EMPLOYMENT_STATUS_OPTIONS[i]}/>)}
			</Picker>

			<Text style={styles.labelText}>{t("phoneNumber")}</Text>
			<PhoneInput
				style={styles.input}
				value={medProfile.phone_number}
				onChange={handlePhoneNumberChange}
				errorMessage={medErrors.phone_number?.join("\n")}
				placeholder="Enter phone number"
			/>
			{phoneError && <Text style={{ color: "red" }}>{phoneError}</Text>}
			
			<Input
				style={styles.input}
				label={t("occupationTitle")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={medProfile.occupation_title}
				onChangeText={(val) => dispatch(setOccupationTitle(val))}
				errorMessage={medErrors.occupation_title?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("whereHearAboutStudy")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={medProfile.heard_about}
				onChangeText={(val) => dispatch(setHeardAbout(val))}
				errorMessage={medErrors.heard_about?.join("\n")}/>
			<Card.Divider/>
			{medProfile.physicians?.map((physician: Physician, i: number) => <PhysicianComponent key={i} physician={physician} index={i} medErrors={medErrors}/>)}
			<Button
				title={t("addPhysician") || "Add Another Physician"}
				onPress={() => dispatch(addPhysician())}
				buttonStyle={styles.button}
				titleStyle={styles.buttonText}
			/>
			{shouldShowSubmitButton ?
				<Button
					title={t("Submit") || "Submit"}
					onPress={handleSubmit}
					buttonStyle={styles.button}
					titleStyle={styles.buttonText}
				/> : null}
		</Card>
	)
}
