
import { Platform, View } from "react-native"
import type { LoginScreenNavigationProp } from "../../../@types/navigation/AuthNavigator"
import { useNavigation } from "@react-navigation/native"
import { resetErrors as clearAuthErrors } from "../../redux/authState"
import { Text, Button, Input, Card } from "@rneui/themed"
import { useState } from "react"
import { logUserIn, resendVerificationEmail } from "../../redux/authState"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { AppState } from "../../redux/store"
import { styles } from "./styles"
import { useTheme } from "@rneui/themed"
import { EmailPasswordLogin } from "../../components/Login/EmailPasswordLogin"
import WebSpinnerComponent from "../../components/Loading/WebSpinnerComponent"



export const LoginScreen = () => {
	const { theme } = useTheme()
	const [loading, setLoading] = useState(false)

	const dispatch = useAppDispatch()

	const dismissAuthErrors = () => {
		dispatch(clearAuthErrors())
	}

	const handleNavigateToSignUp = () => {
		navigation.navigate("SignUp")
		dispatch(clearAuthErrors())

	}
	return (
		<View style={[styles.container, {backgroundColor: theme.colors.white}]}>
			<EmailPasswordLogin setLoading={setLoading}/>
			<WebSpinnerComponent shouldShowSpinner={loading}/>
			{/* <Button title="Resend Verification Email" 
				onPress={handleResendVerificationEmail}/> */}

		</View>
	)
}
