import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AuthState, SignUpData, LoginData, UserLoginResponse, ResendEmailVerificationParams } from "../../@types/redux/auth"
import axios from "axios"

import { checkForInternetAccess, defaultInitialErrorState, sendErrorsToState } from "./util"
import { AppState } from "./store"
import { ErrorResponseData } from "../../@types/axios/error"
import { backendAPIClient } from "../axios/axios"
import i18n from "../../i18n.config"
import { showToast } from "../../toast"
import { resetAssessments, syncAssessments } from "./assessmentState"
import { Platform } from "react-native"
import { DiseaseType } from "../../@types/redux/disease"
import { setSelectedDisease } from "./diseaseState"


let backendBaseUrl = ""
if (window.location.hostname == "localhost") {
	backendBaseUrl = "http://localhost:8000"
}
if (window.location.hostname == "uat-mypacer.pitt.edu") {
	backendBaseUrl = "https://uat-mypacer.pitt.edu" 
}
if (window.location.hostname === "mypacer.pitt.edu") {
	backendBaseUrl = "https://mypacer.pitt.edu"
}

const axiosConfig = {
	withCredentials: true,
	baseURL: backendBaseUrl
}

const authRefreshClient = axios.create(axiosConfig)

const { t } = i18n

const internalInitialState: AuthState = {
	authState: {
		id: 0,
		name: "",
		isAuthenticated: false,
		isSuperuser: false,
		isStaff: false,
		isVerified: false,
		eligibilityStatuses: []
	},
	isLoading: false,
	errors: defaultInitialErrorState
}


export const authSlice = createSlice({
	name: "authState",
	initialState: internalInitialState,
	reducers: {
		newAuthState: (state, action: PayloadAction<AuthState>) => {
			return action.payload
		},
		resetAuthState: () => internalInitialState,
		resetErrors: (state, action: PayloadAction<undefined>) => {
			state.isLoading = false
			state.errors = defaultInitialErrorState
		}
	},
	extraReducers: (builder) => {
		builder.addCase(signUpNewUser.pending, (state, action) => {
			state.isLoading = true
			const message = t("Your account is being created, please wait...", {ns: ["translations"]})
			showToast({ message, kind: "information" })
		}),
		builder.addCase(signUpNewUser.fulfilled, (state, action) => {
			state.isLoading = false
			const message = t("Your account has been successfully created.", {ns: ["translations"]})
			showToast({ message, kind: "success" })
		}),
		builder.addCase(signUpNewUser.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
			const message = t("Failed to create your account. Please try again.", {ns: ["translations"]})
			showToast({ message, kind: "failure" })
		}),
		builder.addCase(logUserIn.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(logUserIn.fulfilled, (state, action) => {
			state.isLoading = false
			if (action.payload) {
				state.authState.id = action.payload.user.pk
				state.authState.name = action.payload.user.name,
				state.authState.isAuthenticated = true,
				state.authState.isSuperuser = action.payload.user.is_superuser
				state.authState.isStaff = action.payload.user.is_staff
				state.authState.isVerified = action.payload.user.verified_emails?.length === 0 ? false : true
				state.authState.eligibilityStatuses = action.payload.user.eligibility_statuses
			}
		}),
		builder.addCase(logUserIn.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(logUserInWithGoogle.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(logUserInWithGoogle.fulfilled, (state, action) => {
			state.isLoading = false
			if (action.payload) {
				state.authState.id = action.payload.user.pk
				state.authState.name = action.payload.user.name,
				state.authState.isAuthenticated = true,
				state.authState.isSuperuser = action.payload.user.is_superuser
				state.authState.isStaff = action.payload.user.is_staff
				state.authState.isVerified = action.payload.user.verified_emails?.length === 0 ? false : true
				state.authState.eligibilityStatuses = action.payload.user.eligibility_statuses
			}
		}),
		builder.addCase(logUserInWithGoogle.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(resendVerificationEmail.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(resendVerificationEmail.fulfilled, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(resendVerificationEmail.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(resetPassword.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(resetPassword.fulfilled, (state, action) => {
			state.isLoading = false
		}),
		builder.addCase(resetPassword.rejected, (state, action) => {
			state.isLoading = false
			sendErrorsToState(state, action)
		}),
		builder.addCase(updateActiveDisease.pending, (state, action) => {
			state.isLoading = true
		}),
		builder.addCase(updateActiveDisease.fulfilled, (state, action) => {
			state.isLoading = false
		}),	
		builder.addCase(updateActiveDisease.rejected, (state, action) => {
			state.isLoading = false
		})
	}
})



export const signUpNewUser = createAsyncThunk<any, SignUpData, {state: AppState, rejectValue: ErrorResponseData}>(
	"signUpNewUser",
	async (data: SignUpData, { dispatch, rejectWithValue }) => {
			
		await checkForInternetAccess()
		// dispatch(resetErrors())

		try {
			const response = await authRefreshClient.post("api/v1/auth/registration/", data)
			console.log("response from create user in redux: ", response)
			dispatch(resetErrors())
			return response
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	})

export const signUpNewUserWithGoogle = createAsyncThunk<any, {code:string}, {state: AppState, rejectValue: ErrorResponseData}>(
	"signUpNewUserWithGoogle",
	async (data: {code:string}, { dispatch, rejectWithValue }) => {
			
		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const response = await authRefreshClient.post("api/v1/auth/registration/", data)
			console.log("response from create user in redux: ", response)
			return response
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	})

export const logUserIn = createAsyncThunk<UserLoginResponse, LoginData, {state: AppState, rejectValue: ErrorResponseData}>(
	"logUserIn",
	async (data: LoginData, { dispatch, rejectWithValue, getState }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		const diseaseState = getState().diseaseState

		try {
			const response = await authRefreshClient.post("api/v1/auth/login/", data)

			// dispatch(setMedicalProfile({ medical_profile: response.data.medical_profiles[0] }))
			console.log("response: ", response)
      
			if (diseaseState.selected) dispatch(syncAssessments(diseaseState.selected.id))
			// if (diseaseState.selected) dispatch(getQuestionairesForAssessments(diseaseState.selected.id))
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				showToast({message: t("logUserInRejected"), kind: "failure"})
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	})


export const signUserOut = createAsyncThunk<void, undefined, {state: AppState, rejectValue: ErrorResponseData}>(
	"signUserOut",
	async (_, { dispatch }) => {

		await dispatch(resetErrors())
		await dispatch(resetAuthState())
		await dispatch(resetAssessments())
	})

export const resendVerificationEmail = createAsyncThunk<any, ResendEmailVerificationParams, {state: AppState, rejectValue: ErrorResponseData}>(
	"resendVerificationEmail",
	async (emailData, { dispatch, rejectWithValue }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		try {

			const response = await backendAPIClient.post("api/v1/auth/registration/resend-email/", emailData)
			console.log(response)

		} catch (error) {
			console.log("error sending request to resend email: ", error)
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			}
			else {
				throw error
			}
		}

	}
)


export const logUserInWithGoogle = createAsyncThunk<UserLoginResponse, {code: string, navigation: any}, {state: AppState, rejectValue: ErrorResponseData}>(
	"logUserInWithGoogle",
	async ({code, navigation},  { dispatch, rejectWithValue, getState }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		const diseaseState = getState().diseaseState
		let url = ""
		switch (Platform.OS) {
		case "ios":
			url = "api/v1/auth/google_ios/login/"
			break
		case "android":
			url = "api/v1/auth/google_android/login/"
			break
		case "web":
			url = "api/v1/auth/google_web/login/"
			break
		}

		try {
			const response = await authRefreshClient.post(url, {code})

			// dispatch(setMedicalProfile({ medical_profile: response.data.medical_profiles[0] }))
			console.log("response: ", response)
      
			if (diseaseState.selected) dispatch(syncAssessments(diseaseState.selected.id))
			// if (diseaseState.selected) dispatch(getQuestionairesForAssessments(diseaseState.selected.id))
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				console.log("error logging in with google: ", error)
				if (error.response?.data.detail === "User must complete pre-screening before logging in.") {
					navigation.navigate("DiseaseIntroduction")
					showToast({message: "User has not completed pre-screening. Please complete pre-screening before logging in.", kind: "failure"})
				}
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	})



export const resetPassword = createAsyncThunk<UserLoginResponse, {email:string}, {state: AppState, rejectValue: ErrorResponseData}>(
	"resetPassword",
	async (data: {email:string}, { dispatch, rejectWithValue, getState }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())

		try {
			const response = await authRefreshClient.post("api/v1/auth/password/reset/", data)

			console.log("response: ", response)
      
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	})


export const updateActiveDisease = createAsyncThunk<UserLoginResponse, {disease:DiseaseType}, {state: AppState, rejectValue: ErrorResponseData}>(
	"updateActiveDisease",
	async (data: {disease:DiseaseType}, { dispatch, rejectWithValue, getState }) => {

		await checkForInternetAccess()
		dispatch(resetErrors())
		const { id } = getState().authState.authState

		try {
			const response = await backendAPIClient.patch(`api/v1/users/${id}/`, {active_disease: data.disease.id})
			dispatch(setSelectedDisease(data.disease))

			console.log("response: ", response)
      
			return response.data
		} catch (error) {
			if (axios.isAxiosError(error)) {
				throw rejectWithValue(error.response?.data)
			} else {
				throw error
			}
		}
	})


// actions are generated by the createSlice function
export const { newAuthState, resetAuthState, resetErrors } = authSlice.actions

export default authSlice.reducer
