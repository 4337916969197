import { ScrollView } from "react-native"
import { LoadingSpinner } from "../../components/Loading/LoadingSpinner"
import { MedicalProfile } from "../../components/MedicalProfile/MedicalProfile"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { updateMedicalProfile } from "../../redux/medicalProfileState"
import { AppState } from "../../redux/store"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import { showToast } from "../../../toast"
import { t } from "i18next"
import { ConsentForm } from "../../components/Consent/Consent"
import { useState } from "react"


export const ProfileScreen = () => {
	const dispatch = useAppDispatch()

	const userState = useAppSelector((state: AppState) => state.authState.authState)  
	const medProfile = useAppSelector((state: AppState) => state.medicalProfileState.profile)
	const medProfileLoading = useAppSelector((state: AppState) => state.medicalProfileState.isLoading)
	const [showMedSignature, setShowMedSignature] = useState(true)

	const validPhysicianPhoneNumbers = () => {
		if (medProfile.physicians.some((p) => p.phone_number && !isPossiblePhoneNumber(p.phone_number))) {
			return false
		}
		return true
	}

	const validPhoneNumber = () => {
		const validPhysicianNumbers = validPhysicianPhoneNumbers()
		if (medProfile.phone_number && (!isPossiblePhoneNumber(medProfile.phone_number) || !validPhysicianNumbers)) {
			showToast({ message: t("invalidPhoneNumber"), kind: "failure" })
			return false
		}
		return true
	}

	const handleMedProfileUpdate = async () => {
		const validNumber = validPhoneNumber()
		if (!validNumber) {
			return
		}
		await dispatch(updateMedicalProfile())
	}

	return (
		<ScrollView>
			<LoadingSpinner isLoading={medProfileLoading} message="Updating medical profile..."/>
			<MedicalProfile handleSubmit={handleMedProfileUpdate} userId={userState.id} shouldShowSubmitButton={true} />
			<ConsentForm 
				existingSignature={medProfile.medical_release_signature}
				shouldShowSignature={showMedSignature}
			/>
		</ScrollView>
	)
}
