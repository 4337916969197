import { View } from "react-native"
import { Text, Button, Input, Card } from "@rneui/themed"
import * as Linking from "expo-linking"
import { styles } from "../../screens/Login/styles"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { useState } from "react"
import { logUserIn, resendVerificationEmail } from "../../redux/authState"
import { AppState } from "../../redux/store"
import { GoogleLogin } from "./GoogleLogin"

interface EmailPasswordLoginProps {
	setLoading: (loading: boolean) => void
}

export const EmailPasswordLogin = ({setLoading}: EmailPasswordLoginProps) => {

	const dispatch = useAppDispatch()

	const authState = useAppSelector((state: AppState) => state.authState)

	const [password, setPassword] = useState("")
	const [email, setEmail] = useState("")

	let backendBaseUrl = ""
	if (window.location.hostname == "localhost") {
		backendBaseUrl = "http://localhost:8000"
	}
	if (window.location.hostname == "uat-mypacer.pitt.edu") {
		backendBaseUrl = "https://uat-mypacer.pitt.edu" 
	}
	if (window.location.hostname === "mypacer.pitt.edu") {
		backendBaseUrl = "https://mypacer.pitt.edu"
	}

	const passwordResetUrl = `${backendBaseUrl}/accounts/password/reset/`

	const handleLogin = async () => {
		const loginData = { password, email }
		await dispatch(logUserIn(loginData))
	}

	const handleResendVerificationEmail = async () => {
		await dispatch(resendVerificationEmail({email}))
	}

	const handleResetPassword = async () => {
		Linking.openURL(passwordResetUrl)
	}


	return (
		<View>
			<Card containerStyle={styles.cardOuterContainer} wrapperStyle={styles.cardInnerContainer}>
				<Card.Title h3>Log In With Google</Card.Title>
				<GoogleLogin setLoading={setLoading}/>
				<Card.Divider style={{paddingTop: 24}} />

				<Card.FeaturedSubtitle style={styles.cardSubtitle}>
          Or use your email and password
				</Card.FeaturedSubtitle>
				<Input
					style={styles.inputInnerContainer}
					placeholder="Email"
					value={email}
					onChangeText={setEmail}
					autoCapitalize="none"
					textContentType="emailAddress"
					autoCorrect={false}
					keyboardType="email-address"
					autoComplete="email"
					errorMessage={authState.errors.email?.join("\n")}
					testID="enterEmail"
				/>				
				<Input
					style={styles.inputInnerContainer}
					placeholder="Password"
					value={password}
					onChangeText={setPassword}
					secureTextEntry={true}
					errorMessage={authState.errors.password?.join("\n")}
					testID="enterPassword"
				/>
				
			</Card>
			<Button
				style={styles.button}
				title="Log In"
				onPress={handleLogin}
				testID="loginSubmit"
			/>
			<Text hyperlink onPress={handleResetPassword} style={styles.emailVerificationText}>Forgot My Username/Password</Text>
		</View>
	)
}
