import { Button, Input, Card } from "@rneui/themed"
import { useTranslation } from "react-i18next"
import { PhysicianComponentProps } from "../../../@types/components/physician"
import { useAppDispatch } from "../../redux/hooks"
import { removePhysicianAtIndex, setPhysicianFaxNumber, setPhysicianFirstName, setPhysicianLastName, setPhysicianOfficeAddressLine1, setPhysicianOfficeAddressLine2, setPhysicianOfficeAddressPostalCode, setPhysicianOfficeCity, setPhysicianOfficeCountry, setPhysicianOfficeStateProvince, setPhysicianPhoneNumber, setPhysicianSpecialty } from "../../redux/medicalProfileState"
import { Address } from "../Address/Address"
import { styles } from "./styles"
import "react-phone-number-input/style.css"
import PhoneInput from "react-phone-number-input"
import { isPossiblePhoneNumber } from "react-phone-number-input"
import { Text } from "@rneui/themed"
import { useState } from "react"


export const PhysicianComponent = (props: PhysicianComponentProps) => {
	const { physician, addressErrors, index, medErrors } = props


	const { t } = useTranslation(["translation", "screens"])
	const dispatch = useAppDispatch()
	const physicianProperty = `physician${index + 1}`

	// Local state to track phone number validation error
	const [phoneError, setPhoneError] = useState<string | null>(null)

	const handlePhoneNumberChange = (phone: string | undefined) => {
		dispatch(setPhysicianPhoneNumber({ index, phone: phone || "" }))

		// Validate phone number using isPossiblePhoneNumber
		if (phone && !isPossiblePhoneNumber(phone)) {
			setPhoneError("Invalid phone number")
		} else {
			setPhoneError(null) // Clear error if phone number is valid
		}
	}

	return (
		<Card>
			<Card.Title h3>Physician #{index+1}</Card.Title>
			<Card.Divider/>
			<Input
				style={styles.input}
				label={t("physicianFirstName")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={physician.first_name}
				onChangeText={(name) => dispatch(setPhysicianFirstName({index, name}))}
				errorMessage={medErrors?.[`${physicianProperty}_first_name`]?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("physicianLastName")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={physician.last_name}
				onChangeText={(name) => dispatch(setPhysicianLastName({index, name}))}
				errorMessage={medErrors?.[`${physicianProperty}_last_name`]?.join("\n")}/>
			<Input
				style={styles.input}
				label={t("physicianSpecialty")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={physician.specialty}
				onChangeText={(specialty) => dispatch(setPhysicianSpecialty({index, specialty}))}
				errorMessage={medErrors?.[`${physicianProperty}_specialty`]?.join("\n")}/>
			
			<Text style={{marginBottom: 16}}>{t("physiciansPhoneNumber")}</Text>
			<PhoneInput
				style={styles.input}
				value={physician.phone_number}
				onChange={handlePhoneNumberChange}
				errorMessage={medErrors?.[`${physicianProperty}_phone_number`]?.join("\n")}
				placeholder="Enter phone number"
			/>
			{phoneError && <Text style={{ color: "red" }}>{phoneError}</Text>}
			
			<Input
				style={styles.input}
				label={t("faxNumber")}
				labelStyle={{ marginBottom: 8, marginTop: 6}}
				value={physician.fax_number}
				onChangeText={(fax) => dispatch(setPhysicianFaxNumber({index, fax}))}
				errorMessage={medErrors?.[`${physicianProperty}_fax_number`]?.join("\n")}/>
			<Address 
				index={index} 
				office_address={physician.office_address} 
				addressErrors={addressErrors} 
				medErrors={medErrors}
			/>
			{ index > 0 ?
				<Button
					title={`${t("removePhysician")} #${index+1}` || "Remove This Physician"}
					onPress={() => dispatch(removePhysicianAtIndex(index))}
					buttonStyle={styles.button}
					titleStyle={styles.buttonText}
				/> : null
			}
		</Card>
	)
}
